import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Particle from "../shared/particle"

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (  <Layout>
    <Particle />
    <div className="flex flex-wrap relative blur">
      <div className="w-full md:w-2/12 mt-8 text-right">
        <div className="mr-4">
          <Link to="/" ><img className="z-40 mr-4" title="pikseladam" alt="pikseladam logo"  src="https://pikseladam.com/pikseladam.png" /></Link>
          <p className="mt-4">I code stuff & design things. Curiosity and boredom were used during the construction of the site.</p>
          <ul className="mt-4">
            <li>            
              <a href="https://github.com/tcgumus/" className="hover:underline mt-3" rel="me">github</a>
            </li>
            <li>
              <a href="https://dev.to/tcgumus" className="hover:underline mt-3" rel="me">dev.to</a>
            </li>
            <li>
              <a href="https://twitter.com/tc_gumus" className="hover:underline" rel="me"> twitter</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full md:w-6/12  mx-auto text-lg">
        <div className=" overflow-hidden ">
          <div className=" text-blue-500 text-lg px-4">
            <div className="w-full">
              <img className="u-photo hidden" src="https://pikseladam.com/avatar.jpg" alt="pikseladam logo"/>
              <p className="p-note hidden">I'm a Lead PLM Systems & Software Engineer. I code stuff & design things. #Arts for art. Working on http://turrsu.com and https://pikseladam.com for fun. #webmentions on pikseladam.</p>
            </div>
          </div>
          <div id="yazilar" className="px-4 py-8">
            <div className="blog-posts">
              {posts
                .filter(post => post.node.frontmatter.title.length > 0)
                .map(({ node: post }) => {
                  return (
                    <div className="blog-post-preview" key={post.id}>
                      <div>
                        <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                        <span className=" text-gray-700 text-sm font-medium"> -  {post.frontmatter.date}</span>
                      </div>
                      <div className="text-base">{post.excerpt}</div>
                    </div>
                  )
                })}
            </div>

            <a href="https://dev.to/tcgumus/phoenix-framework-or-ruby-on-rails-4a2p" className="block mb-6 hover:underline">
              <div id="yazi" className=" ">
                <div className=" text-black font-bold ">Phoenix Framework or Ruby on Rails <span className=" text-gray-700 text-sm font-medium"> - Şubat 24 2020</span></div>
              </div>
            </a>
            <a href="https://dev.to/tcgumus/a-way-to-create-your-own-multi-device-supported-cloud-based-markdown-and-note-taking-platform-4m7f" className="block mb-6 hover:underline">
              <div id="yazi" className=" ">
                <div className=" text-black font-bold ">A way to create your own, multi device supported, cloud based markdown and note taking platform. <span className=" text-gray-700 text-sm font-medium"> - Ocak 11 2020</span></div>
              </div>
            </a>
            <a href="https://dev.to/tcgumus/rails-6-webpacker-settings-for-production-1f1e"  className="block mb-6 hover:underline">
              <div id="yazi" className=" ">
                <div className=" text-black font-bold ">Rails 6 & Webpacker Settings for Production. <span className=" text-gray-700 text-sm font-medium"> - Aralık 28 2019</span></div>
              </div>
            </a>
            <a href="https://dev.to/tcgumus/install-gatsby-add-tailwind-css-to-it-and-deploy-it-to-netlify-a-simple-website-example-3cdh"  className="block mb-6 hover:underline">
              <div id="yazi" className=" ">
                <div className=" text-black font-bold ">Install Gatsby, add Tailwind CSS to it and deploy it to Netlify. A simple website example. <span className=" text-gray-700 text-sm font-medium"> - Aralık 21 2019</span></div>
              </div>
            </a>
            <a href="https://dev.to/tcgumus/how-to-install-tailwind-css-on-rails-6-0-2h3f"  className="block mb-6 hover:underline">
              <div id="yazi" className=" ">
                <div className=" text-black font-bold ">How to install Tailwind CSS on Rails 6.0 <span className=" text-gray-700 text-sm font-medium"> - Aralık 16 2019</span></div>
              </div>
            </a>
          </div>
        </div>
        <div className="text-sm">
          <div className="mt-4 text-black px-4">
            <a href="https://xn--sr8hvo.ws/%F0%9F%90%AB%F0%9F%91%92%F0%9F%98%92/previous">←</a>
            An IndieWeb Webring 🕸💍
            <a href="https://xn--sr8hvo.ws/%F0%9F%90%AB%F0%9F%91%92%F0%9F%98%92/next">→</a>
          </div>
          <div className="px-4 py-2 mb-12">#pikseladam has coded with gastly, tailwindcss, deployed with netlify and supports webmentions</div>
        </div>  
      </div>
      <div className="w-full md:w-4/12 text-base">
        <div className="px-4 pt-8 pb-2">
          <div className="text-teal-900 text-lg mb-1 font-bold">Projelerim / Projects</div>
          <div><a href="http://www.turrsu.com" className="block text-yellow-800 py-2 hover:underline"># turrsu.com <i className="text-black">sosyal bloglarınızı oluşturun, paylaşın.</i></a></div>
          <div>
            <a href="https://saman-dere-font.vercel.app/" className="block text-yellow-800 py-2 hover:underline"># Saman Dere Font <i className="text-black"> web için hazırlanmış sans-serif font.</i></a> 
          </div>
          <div><a href="http://www.nolmus.com" className="block text-yellow-800 py-2 hover:underline"># nolmus.com <i className="text-black">farklı başlıklarda haberleri sade ve reklamsız bir şekilde sunan haber sitesi.</i></a></div>
          <div><a href="https://yuzlercecesit.com" className="block text-yellow-800 py-2 hover:underline"># yuzlercecesit.com <i className="text-black">pos entegre online ürün satış platformu.</i></a></div>
          <div><a href="https://yuzlercemama.com" className="block text-yellow-800 py-2 hover:underline"># yuzlercemama.com <i className="text-black">pos entegre online mama satış platformu.</i></a></div>
          <div><a href="https://github.com/tcgumus/AnkaraSerif" className="block text-yellow-800 py-2 hover:underline"># Ankara Font <i className="text-black">1930'ların havasını yakalayan dergi ve gazete fontu çalışması.</i></a></div>
          <div><a href="/uses/" className="block text-yellow-800 py-2 mb-2 hover:underline"># Tools that i use <i className="text-black">inspired by web bos project uses.tech.</i></a></div>
          <div><a href="/ikizer-malzeme-agirlik-hesaplama-uygulamasi/" className="block text-yellow-800 py-2 mb-2 hover:underline"># İkizer Uygulaması <i className="text-black">kolay kullanımlı ücretsiz malzeme ağırlık hesaplama uygulaması.</i></a></div>
        </div>
      </div>
    </div>
  </Layout>
)
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`